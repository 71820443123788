const reviews = document.querySelectorAll('.reviews__item')

reviews.forEach(i => {
	const playBtn = i.querySelector<HTMLButtonElement>('button[data-action="play"]')

	if (!playBtn) {
		const msg = `.reviews__item doesnt have button[data-action="play"]\n`
		return console.error(msg, 'Element:\n', i)
	}

	const video = i.querySelector<HTMLMediaElement>('video')

	if (!video) {
		const msg = '.reviews__item doesnt have video element\n'
		return console.error(msg, 'Element:\n', i)
	}

	playBtn.addEventListener('click', () => {
		video.paused ? video.play() : video.pause()
		i.classList.toggle('playing')
	})
})