import { loadYMap } from 'features/yMap/loadYMap'
import { createYMap, MapConfig } from 'features/yMap/createMap'

const options: MapConfig = {
	placemarkOptions: {
		iconLayout: 'default#image',
		iconImageHref: require('media/static/placemark.svg'),
		iconImageSize: [ 47, 70 ],
		iconImageOffset: [ -24, -68 ],
	},
}

if (document.querySelector('.contacts__map'))
	loadYMap('818f9c4f-11a3-4f71-82b7-1b2a7f77f741')
	  .then(() => createYMap('.contacts__map', options))
	  .catch((err) => {
		  console.error('Error while initializing map in .contacts.\nError:\n', err)
	  })