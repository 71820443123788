import Swiper, { Navigation, Pagination } from 'swiper'

new Swiper('.reviews .swiper', {
	centeredSlides: true,
	slidesPerView: 'auto',
	spaceBetween: -90,
	observer: true,
	navigation: {
		prevEl: '.swiper-btn-prev',
		nextEl: '.swiper-btn-next',
	},
	pagination: {
		el: '.swiper-pagination',
	},
	on: {
		slideChangeTransitionStart: (swiper) => {
			const playingEl = swiper.el.querySelector<HTMLElement>('.playing')
			if (!playingEl) return

			const videoEl = playingEl.querySelector('video')
			if (videoEl) videoEl.pause()
			playingEl.classList.remove('playing')
		},
		slideChangeTransitionEnd: (swiper) => {
			swiper.update()
		},
	},
	breakpoints: {
		500: {
			spaceBetween: -100,
		}
	},
	modules: [ Navigation, Pagination ]
})