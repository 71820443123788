const tabContentEls = document.querySelectorAll<HTMLElement>('.content_tab')

if (window.matchMedia('(max-width: 1100px)').matches)
	tabContentEls.forEach(initTabContentEl)

window.addEventListener('resize', () => {
	if (window.matchMedia('(max-width: 1100px)').matches) {
		if (tabContentEls[0].dataset.activated === 'true') return
		tabContentEls.forEach(initTabContentEl)
	} else {
		tabContentEls.forEach((el) => {
			el.dataset.activated = 'false'
			el.style.maxHeight = 'initial'
		})
	}
})

function initTabContentEl(el: HTMLElement) {
	const title = el.querySelector('.content__title')
	if (!title) return

	const titleHeight = (): string => {
		const title = el.querySelector<HTMLElement>('.content__title')
		if (title) return `${ title.offsetHeight }px`
		else return 'initial'
	}

	const innerHeight = (): string => {
		const inner = el.querySelector<HTMLElement>('.content__inner')
		if (inner) return `${ inner.offsetHeight }px`
		else return 'initial'
	}

	el.style.maxHeight = titleHeight()
	el.dataset.activated = 'true'

	title.addEventListener('click', () => {
		if (el.dataset.activated === 'false') return

		if (el.classList.contains('opened'))
			el.style.maxHeight = titleHeight()
		else
			el.style.maxHeight = innerHeight()

		el.classList.toggle('opened')
	})

	window.addEventListener('resize', () => {
		if (el.dataset.activated === 'false') return

		if (el.classList.contains('opened'))
			el.style.maxHeight = innerHeight()
		else
			el.style.maxHeight = titleHeight()
	})
}